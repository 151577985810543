import { AppProps } from 'next/app'
import { Analytics as VercelAnalytics } from '@vercel/analytics/react'

import 'styles/global.css'

import { FiletreeProvider } from 'utils/useFiletree'
import { SupportedLangaugesWithTextMateProvider } from 'utils/languages'
import { usePageViews } from 'utils/analytics/usePageViews'
import { usePostHog } from 'utils/analytics/posthog'
import Layout from 'components/Layout'

function App({
  Component,
  pageProps,
}: AppProps) {

  usePostHog()
  usePageViews()

  return (
    <SupportedLangaugesWithTextMateProvider>
      <FiletreeProvider>
        <Layout
          category={pageProps.category}
          example={pageProps.example}
          guide={pageProps.guide}
          subcategory={pageProps.subcategory}
          toc={pageProps.toc}
        >
          <Component {...pageProps} />
        </Layout>
        <VercelAnalytics/>
      </FiletreeProvider>
    </SupportedLangaugesWithTextMateProvider>
  )
}

export default App
